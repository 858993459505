import React, { Component } from 'react';
import Content from '../common/containers/Content';
import PublicPage from '../common/containers/PublicPage';
import { withNamespaces } from 'react-i18next';
import Config from '../config';
import Ajax from '../common/ajax';
import Waiting from '../common/containers/Waiting';
import Col from '../common/containers/Col';
import Row from '../common/containers/Row';

class TermsOfServicePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      termOfService: null,
    };
  }

  componentDidMount() {
    this.fetchTOS();
  }

  fetchTOS() {
    this.setState((state) => ({
      ...state,
      processing: true,
    }));
    const url = `${Config.apiHost}term-of-service/`;
    Ajax.get(url)
      .done((termsOfService) => {
        this.setState((state) => ({
          ...state,
          termOfService: termsOfService[0],
          processing: false,
        }));
      })
      .fail(() => {
        this.setState((state) => ({
          ...state,
          processing: false,
        }));
      });
  }

  render() {
    if (!this.state.termOfService) {
      return null;
    }

    const { i18n } = this.props;
    return (
      <PublicPage title={i18n.t('Termos de Serviço')}>
        <Content>
          <Waiting isProcessing={this.state.processing}>
            <Row>
              <Col md={8} mdOffset={2}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.termOfService.content,
                  }}
                />
                <hr />
                <p style={{ textAlign: 'right' }}>FastFormat.co</p>
              </Col>
            </Row>
          </Waiting>
        </Content>
      </PublicPage>
    );
  }
}

export default withNamespaces()(TermsOfServicePage);
